import { Injectable } from '@angular/core';
import { ConsentStatus, ConsentType, FirebaseAnalytics } from "@capacitor-firebase/analytics"
import { Preferences } from '@capacitor/preferences';
import { environment } from '@envs';

interface User {
  id: string;
  name: string;
  email: string;
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor() { }

  async initializeAnalytics(user: User) {
    if (environment.production) {
      this.setUser(user)
    }

    const consentTypes = [
      ConsentType.AdPersonalization,
      ConsentType.PersonalizationStorage,
      ConsentType.AdUserData,
      ConsentType.AdStorage,
      ConsentType.AnalyticsStorage,
    ];

    for (let consentType of consentTypes) {
      await FirebaseAnalytics.setConsent({
        type: consentType,
        status: ConsentStatus.Granted
      });
    }
  }

  setUser(user: User) {
    FirebaseAnalytics.setUserId({
      userId: user.id,
    })

    FirebaseAnalytics.setUserProperty({
      key: 'user_name',
      value: user.name
    })

    FirebaseAnalytics.setUserProperty({
      key: 'user_email',
      value: user.email
    })
  }

  logEvent(name: string, params) {
    if (environment.production) {
      FirebaseAnalytics.logEvent({ name, params })
    } else {
      console.info('%clog event', 'color: white; background-color: #61dbfb', name, params)
    }
  }

  setCurrentScreen(screenName: string) {
    if (environment.production) {
      FirebaseAnalytics.setCurrentScreen({ screenName })
    }
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { GoogleMapService } from '@services/google-map/google-map.service';
import { ModalsService } from '@services/modals/modals.service';
import { RouteService } from '@services/route/route.service';
import { ViajesService } from '@services/viajes/viajes.service';
import { doneTripStatus, tripStatuses } from '@app/constants'
import { VehiclesService } from '@services/vehicles/vehicles.service';
import { CupertinoPane, CupertinoSettings } from 'cupertino-pane';
import { ComparadorService } from '@services/modals/comparador/comparador.service';
import { GeolocationService } from '@services/geolocation/geolocation.service';
import { MyWaiisService } from '@services/modals/my-waiis/my-waiis.service';
import { ModalInformService } from '@services/modals/modal-inform/modal-inform.service';

@Component({
  selector: 'app-trip-status',
  templateUrl: './trip-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripStatusComponent implements OnInit {
  trip
  tripStatuses = tripStatuses
  timeToWait = 2.5 * 60 * 1000 // 2.5 Minutes in milliseconds
  requestTimeLimit: number
  progress = 0
  progressInterval: ReturnType<typeof setInterval>
  cancelingTrip: boolean = false

  lastTripState

  requestingTripId

  message: number = 0

  lap: number = 1

  canceledNoDrivers: boolean = false

  time

  timeToShow

  modalTripStatusPresented: boolean = false

  modalTripStatus: CupertinoPane
  modalTripStatusSettings: CupertinoSettings = {
    bottomClose: true,
    fastSwipeClose: false,
    buttonDestroy: true,
    parentElement: 'body',
    breaks: {
      top: {
        enabled: true,
        height: 400,
      },
      middle: {
        enabled: true,
        height: 350,
      },
      bottom: {
        enabled: true,
        height: 160,
      }
    },
    events: {
      onDidDismiss: () => {
        this.myWaiis.setTripStatusVisible(false)

        if (doneTripStatus.includes(this.trip.state)) {
          this.googleMapService.resetMarkers()

          this.routeService.setUbicacion('destino', null, true)

          const userLocation = this.geolocation.location.getValue()
          this.routeService.setUbicacion('origen', userLocation, true)
          this.geolocation.locatedOrigin.next(true)

          this.googleMapService.centerToUserLocation()
        }
        this.vehiclesService.resetActivos()
        this.comparador.dismiss()
      },
      onDidPresent: () => {
        this.myWaiis.setTripStatusVisible(true)
        this.modalTripStatusPresented = true
      },
      onTransitionEnd: () => {
        this.googleMapService.tripStatusBreakpointChanged(this.modalTripStatus.currentBreak());
      },
    }
  }

  constructor(
    private viajesService: ViajesService,
    private modalsService: ModalsService,
    private googleMapService: GoogleMapService,
    private routeService: RouteService,
    private vehiclesService: VehiclesService,
    private ref: ChangeDetectorRef,
    private comparador: ComparadorService,
    private geolocation: GeolocationService,
    private myWaiis: MyWaiisService,
    private modalInformService: ModalInformService
  ) {
    this.viajesService.currentTrip.subscribe(async currentTrip => {
      if (!currentTrip) {
        return
      }

      this.updateModalBreakpoint(currentTrip.state)

      this.requestingTripId = JSON.parse((await Preferences.get({ key: 'requestingTrip' }))?.value)?.tripId

      this.lastTripState = currentTrip.state

      if (this.cancelingTrip && (currentTrip.state === tripStatuses.cancelled_by_rider || currentTrip.state === tripStatuses.cancelled_by_driver || currentTrip?.state === tripStatuses.cancelled_by_system)) {
        this.cancelingTrip = false
      }

      this.handleTripUpdateInterval(currentTrip.state)

      this.trip = currentTrip

      this.ref.detectChanges()
    })

    this.modalsService.modalTripStatus.subscribe((modal) => {
      switch (modal?.action) {
        case 'present':
          this.modalTripStatus?.present(...modal?.params)
          break
        case 'moveToBreak':
          if (this.modalTripStatusPresented) {
            this.modalTripStatus?.moveToBreak(modal?.params[0])
            break
          }
        case 'hide':
          if (this.modalTripStatusPresented) {
            this.modalTripStatus?.hide()
            break
          }
        case 'destroy':
          if (this.modalTripStatusPresented) {
            this.modalTripStatus?.destroy(...modal?.params)
            break
          }
      }
    })
  }

  updateModalBreakpoint(currentTripState) {
    if (
      currentTripState === tripStatuses.searching_driver ||
      currentTripState === tripStatuses.cancelled_by_rider ||
      currentTripState === tripStatuses.cancelled_by_system ||
      currentTripState === tripStatuses.cancelled_by_driver
    ) {
      this.modalsService.moveModalToBreak('modalTripStatus', 'bottom')
    } else if (
      currentTripState !== tripStatuses.searching_driver &&
      currentTripState !== tripStatuses.cancelled_by_rider &&
      currentTripState !== this.tripStatuses.cancelled_by_system &&
      currentTripState !== this.tripStatuses.cancelled_by_driver
    ) {
      this.modalsService.moveModalToBreak('modalTripStatus', 'middle')
    }
  }

  handleTripUpdateInterval(currentTripState) {
    if (
      (this.trip?.state === tripStatuses.created && currentTripState === tripStatuses.searching_driver) ||
      (!this.progressInterval && this.trip?.state === tripStatuses.searching_driver)
    ) {
      this.lap = 1
      this.clearInterval()
      this.setLoader()
    }

    if (currentTripState !== tripStatuses.searching_driver && this.progressInterval) {
      this.clearInterval()
    }
  }

  setLoader = async (force: boolean = false) => {
    const savedTripId = this.viajesService.currentTrip.getValue().id
    if (savedTripId === this.requestingTripId && !force) {
      this.requestTimeLimit = parseInt(JSON.parse((await Preferences.get({ key: 'requestingTrip' }))?.value).timeLimit)
    } else {
      this.requestTimeLimit = Date.now() + this.timeToWait
      Preferences.set({
        key: 'requestingTrip',
        value: JSON.stringify({
          timeLimit: this.requestTimeLimit,
          tripId: savedTripId
        })
      })
    }

    this.progressInterval = setInterval(async () => {
      this.time = this.requestTimeLimit - Date.now()

      const message = (this.time / 30000) - 5

      this.message = Math.trunc(message * -1)

      if (this.time <= 0) {
        if (this.lap === 2) {
          this.canceledNoDrivers = true
          await this.viajesService.cancelTrip(this.trip.id)
        }
        this.viajesService.updateCurrentTrip({
          ...this.trip,
          state: tripStatuses.our_no_drivers
        })
        this.clearInterval()
      }
    }, 50)
  }

  ngOnInit() {
    this.clearInterval()
    this.modalTripStatus = new CupertinoPane(
      '.modalTripStatus',
      this.modalTripStatusSettings
    )
  }

  get progressBarValue() {
    if (!this.time || this.time < 0) {
      return 0
    }

    const value = ((this.time / this.timeToWait) - 1) * -1
    return value
  }

  get timeToPickUp() {
    if (!this.trip?.extras?.tiempo) {
      return null
    }

    return {time: Math.trunc(this.trip.extras.tiempo / 60)}
  }

  keepWaiting() {
    this.lap = 2
    this.viajesService.updateCurrentTrip({
      ...this.trip,
      state: tripStatuses.searching_driver
    }, true)
    this.setLoader(true)
  }

  clearInterval() {
    this.message = 0
    clearInterval(this.progressInterval)
  }

  cancelTrip() {
    if (!this.cancelingTrip) {
      this.message = 0
      this.cancelingTrip = true
      this.viajesService.cancelTrip(this.trip?.id)
    }
  }

  openInformModal() {
    this.viajesService.tripToReport = this.viajesService.currentTrip.getValue()
    this.modalInformService.openModal()
  }
}

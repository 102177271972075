import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resumen-carpooling',
  templateUrl: './resumen-carpooling.component.html',
  styleUrls: ['./resumen-carpooling.component.scss'],
})
export class ResumenCarpoolingComponent implements OnInit {
  passengers: number = 1

  constructor() { }

  ngOnInit() {}

  increasePassengers() {
    if (this.passengers === 3) {
      return
    }
    this.passengers += 1
  }

  decreasePassengers() {
    if (this.passengers === 1) {
      return
    }
    this.passengers -= 1
  }
}

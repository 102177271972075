import { Component, OnInit } from '@angular/core';
import { formatPrice, round } from '@app/utils';
import { UserService } from '@services/user/user.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit {
  tripExpenses;
  taxiBalance;
  completedRoutesPercentage;
  percentageDiffRoutesCompleted;
  totalCo2Saved;
  co2SavedAsDriverPercentage;
  co2SavedAsPassengerPercentage;
  percentageDiffCo2Saved;

  loaded = false;

  constructor(private user: UserService) {
    const today = new Date().toISOString().split('T')[0];
    const startDate = today.slice(0, 8) + '01';
    const endDate = today;
    this.user.getPoolingData({ startDate, endDate }).finally(() => {
      this.loaded = true
    });

    this.user.carpoolingData.subscribe((data) => {
      this.tripExpenses = formatPrice(data.expenses_saved);
      this.taxiBalance = formatPrice(data.balance_in_waiis);
      this.completedRoutesPercentage =
        data.total_routes > 0
          ? round((data.completed_routes / data.total_routes) * 100, 2)
          : 0;

      this.totalCo2Saved = round(
        data.co2_saved_driver + data.co2_saved_rider,
        2
      );

      this.co2SavedAsDriverPercentage =
        this.totalCo2Saved > 0
          ? round((data.co2_saved_driver / this.totalCo2Saved) * 100, 2)
          : 0;

      this.co2SavedAsPassengerPercentage =
        this.totalCo2Saved > 0
          ? round((data.co2_saved_rider / this.totalCo2Saved) * 100, 2)
          : 0;

      this.percentageDiffRoutesCompleted = round(
        data.percentage_diff_routes_completed,
        2
      );

      const totalPreviousCo2Saved = round(
        data.previous_co2_saved_driver + data.previous_co2_saved_rider,
        2
      );

      this.percentageDiffCo2Saved =
        totalPreviousCo2Saved > 0
          ? round(
              ((this.totalCo2Saved - totalPreviousCo2Saved) /
                totalPreviousCo2Saved) *
                100,
              2
            )
          : 0;

      this.percentageDiffCo2Saved =
        totalPreviousCo2Saved > 0
          ? round(
              ((this.totalCo2Saved - totalPreviousCo2Saved) /
                totalPreviousCo2Saved) *
                100,
              2
            )
          : 0;
    });
  }

  get co2SavedAsDriverPercentageClass() {
    return `w-[${this.co2SavedAsDriverPercentage}%]`
  }

  ngOnInit() {}
}
